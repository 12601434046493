import React from "react"
import Layout from "../../../../components/layout"
import SEO from "../../../../components/seo"
import { StaticQuery, graphql, Link } from 'gatsby'
import "../../../../assets/css/closedform.css";

let title = 'LS thresholds: Closed-form relationships';
let metatitle = 'As Built Piers - LS thresholds: Closed-form relationships';
let description = '';
let metadescription = description;





class ClosedForm extends React.Component{
  //javascript
  state = {
    
  }

  
  render(){
    // if (typeof window !== 'undefined') {
    // $(document).ready(function () {
    //   $('.anchornav button').click(function(){
    //     var target = $(this).attr('data-target');
    //     var off = 20;
    //     var offset = $('#'+target).offset().top - off;
    //     $('html,body').animate({scrollTop: offset},'slow');
    //   });
    // });
    // }
    
    return(
      
        <Layout bodyclass="closed" title={title}>
          <SEO
            title={metatitle}
            description={metadescription}
          />
          <nav className="anchornav">
            <ul>
          {this.props.closedforms2.edges.map(edge=>{
            let entity = edge.node;
            return(
              <li key={entity.id}>
                <Link className="more" to={`/bridge-specific/capacity/as-built-piers/ls-closed-form/${entity.slug}`}>{entity.Name}</Link>
              </li>
            )
            })}
          
          </ul>
          </nav>
          
        </Layout>
      )
    }
}


export default () => (
  <StaticQuery
  query={graphql`
  query MyQuery1 {
  allStrapiCapacityAsBuiltClosedForms(sort: {fields: id, order: ASC}){
    edges {
      node {
        id
        Name
        slug
      }
    }
  }
}
    `
  }
    render={(data) => (
      <ClosedForm closedforms2={data.allStrapiCapacityAsBuiltClosedForms} />
    )}
  />
)
